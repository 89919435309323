<header>
    <div class="header" id="header">
        <div class="conta_iner">
            <nav class="navbar navbar-inverse">
                <div class="navbar-header">
                    <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
                    <i class="fa fa-bars"  aria-hidden="true" (click)="changeHeader()"></i>
                    </button>
                    <a class="navbar-brand">
                        <figure id="logo-img"><img routerLink="/" src="assets/images/ic_logo.png"></figure>
                        <figure id="logo-img-2" class="displaynone"><img routerLink="/" src="assets/images/logo.png"></figure>
                    </a>
                </div>
                <div class="collapse navbar-collapse" id="myNavbar">
                    <div class="haeder-main">
                        <ul class="nav navbar-nav">
                            <li><a routerLink="/" [class]="getClassActive(0)">Home</a></li>
                            <li>
                                 <a [class]="getClassActive(1)">Services <i class="fa fa-caret-down" aria-hidden="true"></i></a>
                                  <div class="drp-down">
                                    <ul>
                                        <li routerLinkActive="active-link" routerLink="/services/ui-ux-design">UI/UX Designing</li>
                                        <li routerLinkActive="active-link" routerLink="/services/website-development">Web Development</li>
                                        <li routerLinkActive="active-link" routerLink="/services/quality-assurance">Quality Assurance</li>
                                        <li routerLinkActive="active-link" routerLink="/services/mobile-application-development">Mobile App Development</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <a [class]="getClassActive(2)">Solutions <i class="fa fa-caret-down" aria-hidden="true"></i></a>
                                <div class="drp-down">
                                    <ul>
                                        <li routerLinkActive="active-link" routerLink="/solutions/food-delivery-solution">Food Delivery Solution</li>
                                        <li routerLinkActive="active-link" routerLink="/solutions/grocery-delivery-solution">Grocery Delivery Solution</li>
                                        <li routerLinkActive="active-link" routerLink="/solutions/car-booking-solution">Cab Booking Solution</li>
                                        <li routerLinkActive="active-link" routerLink="/solutions/ondemand-services-solution">On Demand Services</li>
                                    </ul>
                                </div>
                            </li>
                            <!-- <li><a href="#work">Our Work</a></li> -->
                            <!-- <li><a routerLink="/resources">Resources</a></li> -->
                            <li><a style="cursor: pointer;" data-toggle="modal" data-target="#getquote">Contact Us</a></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</header>