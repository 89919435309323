import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
import { Title } from "@angular/platform-browser";
import * as $ from 'jquery';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, set, onValue, get, query, limitToFirst } from "firebase/database";
import { getAuth } from "firebase/auth";
import Swal from "sweetalert2";

// Set the configuration for your app
// TODO: Replace with your project's config object
const firebaseConfig = {
  apiKey: "AIzaSyBylgL6TnlyzPTHsbeL5j4ZE_kRSlTGisY",
  authDomain: "rcfirebaselive.firebaseapp.com",
  // For databases not in the us-central1 location, databaseURL will be of the
  // form https://[databaseName].[region].firebasedatabase.app.
  // For example, https://your-database-123.europe-west1.firebasedatabase.app
  databaseURL: "https://rcfirebaselive-default-rtdb.firebaseio.com",
  storageBucket: "rcfirebaselive.appspot.com"
};
const app = initializeApp(firebaseConfig);
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  data: {
    name: string,
    email: string,
    phoneNo: number,
    budget: number,
    requirements: string
  } = {
      name: null,
      email: null,
      phoneNo: null,
      budget: null,
      requirements: null
    }

  errors: {
    name: { required: boolean },
    email: { required: boolean, valid: boolean },
    phoneNo: { required: boolean },
    budget: { required: boolean },
    requirements: { required: boolean },
  } = {
      name: { required: false },
      email: { required: false, valid: false },
      phoneNo: { required: false },
      budget: { required: false },
      requirements: { required: false },
    }
  isSubmitted: boolean = false;
  constructor(private title: Title) {
    this.title.setTitle('Raisincode | Home');
    this.getLeads();
  }

  checkValidity(): boolean {
    if(this.isSubmitted) {
      let keys = Object.keys(this.data);
      let isValid: boolean = true;
      for (var i = 0; i < keys.length; i++) {     
        if (!this.data[keys[i]]) {
          isValid = false;
          this.errors[keys[i]].required = true;
          keys[i]==='email' && (this.errors.email.valid = false);
        } else {
          this.errors[keys[i]].required = false;
        }
        if (keys[i] === 'email' && this.data.email) {
          this.errors[keys[i]].required = false;
          let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
          if(!regex.test(this.data.email)) this.errors.email.valid = true;
          else {this.errors.email.valid = false;}
        }
      }
      console.log(this.errors)
      return isValid;
    }
  }

  generateLead() {
    this.isSubmitted = true;
    let isValid: boolean = this.checkValidity();
    console.log(this.errors)
    if (isValid) {
      let userId = this.data.name + this.data.email ? this.data.email : JSON.stringify(this.data.phoneNo) + new Date().getTime();
      userId = userId.replace(/[^A-Z0-9]/ig, "_") + new Date().getTime();
      const database = getDatabase(app);
      if (this.data.email || this.data.phoneNo) {
        set(ref(database, 'leads/' + userId), {
          name: this.data.name || '',
          email: this.data.email || '',
          phoneNo: this.data.phoneNo || '',
          budget: this.data.budget || null,
          requirements: this.data.requirements || '',
        });
        Swal.fire({
          title: "<i>Your response has been submitted</i>",
          html: "We typically respond within 24 Hours !",
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#38dcad',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Continue browsing'
        }).then((result) => {
          let keys = Object.keys(this.data);
          for(var i=0; i<keys.length; i++) {
            this.data[keys[i]]=null;
          }
        })
      }
    }
  }


  getLeads() {
    const database = getDatabase(app);
    const starCountRef = ref(database, 'leads');

    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
    });
  }

  ngOnInit() {
    AOS.init({
      duration: 1200,
      once: true,
    })

  }
  slides = [
    { img: "assets/images/ic_laravel.png" },
    { img: "assets/images/ic_angular.png" },
    { img: "assets/images/ic_react.png" },
    { img: "assets/images/ic_sketch.png" },
    { img: "assets/images/ic_mongodb.png" },
    { img: "assets/images/ic_react.png" },
    { img: "assets/images/ic_sketch.png" },
  ];

  slides1 = [
    { img: "assets/images/ic_img5.jpg", text: 'Food delivery', img2: 'assets/images/ic_food.png', route: 'solutions/food-delivery-solution' },
    { img: "assets/images/ic_img6.jpg", text: 'Grocery delivery', img2: 'assets/images/ic_grocery.png', route: 'solutions/grocery-delivery-solution' },
    { img: "assets/images/ic_img7.jpg", text: 'Cab booking', img2: 'assets/images/ic_cab.png', route: 'solutions/car-booking-solution' },
    { img: "assets/images/ic_img8.png", text: 'On demand services', img2: 'assets/images/ic_clean.png', route: 'solutions/ondemand-services-solution' },
    { img: "assets/images/ic_img5.jpg", text: 'Food delivery', img2: 'assets/images/ic_food.png', route: 'solutions/food-delivery-solution' },
    { img: "assets/images/ic_img6.jpg", text: 'Grocery delivery', img2: 'assets/images/ic_grocery.png', route: 'solutions/grocery-delivery-solution' },
    { img: "assets/images/ic_img7.jpg", text: 'Cab booking', img2: 'assets/images/ic_cab.png', route: 'solutions/car-booking-solution' },
    { img: "assets/images/ic_img8.png", text: 'On demand services', img2: 'assets/images/ic_clean.png', route: 'solutions/ondemand-services-solution' },

  ];

  slides2 = [
    { img: "assets/images/ic_quote1.png", para: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry', img0: 'assets/images/ic_qupte2.png' },
    { img: "assets/images/ic_quote1.png", para: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry', img0: 'assets/images/ic_qupte2.png' },
    { img: "assets/images/ic_quote1.png", para: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry', img0: 'assets/images/ic_qupte2.png' },
    { img: "assets/images/ic_quote1.png", para: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry', img0: 'assets/images/ic_qupte2.png' },
    { img: "assets/images/ic_quote1.png", para: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry', img0: 'assets/images/ic_qupte2.png' },
    { img: "assets/images/ic_quote1.png", para: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry', img0: 'assets/images/ic_qupte2.png' },
    { img: "assets/images/ic_quote1.png", para: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry', img0: 'assets/images/ic_qupte2.png' },
    { img: "assets/images/ic_quote1.png", para: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry', img0: 'assets/images/ic_qupte2.png' },
    { img: "assets/images/ic_quote1.png", para: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry', img0: 'assets/images/ic_qupte2.png' },
    { img: "assets/images/ic_quote1.png", para: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry', img0: 'assets/images/ic_qupte2.png' },
    { img: "assets/images/ic_quote1.png", para: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry', img0: 'assets/images/ic_qupte2.png' },
    { img: "assets/images/ic_quote1.png", para: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry', img0: 'assets/images/ic_qupte2.png' },

  ];

  slides3 = [
    { img: "assets/images/ic_mockups.png" },
    { img: "assets/images/ic_mockups.png" },
    { img: "assets/images/ic_mockups.png" },
    { img: "assets/images/ic_mockups.png" },
  ];

  posts: any[] = [
    { post: "assets/images/posts/1.png" },
    { post: "assets/images/posts/2.png" },
    { post: "assets/images/posts/3.png" },
    { post: "assets/images/posts/4.png" },
    { post: "assets/images/posts/5.png" },
    { post: "assets/images/posts/6.png" }
  ]


  slideConfig3 = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1
        }
      },

    ]
  };



  slideConfig1 = {
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    autoplay: false,
    autoplaySpeed: 2000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1
        }
      },

    ]
  };

  slideConfig2 = {
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: false,
    autoplay: true,
    autoplaySpeed: 1500,
    arrows: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1
        }
      },
    ]
  };


  slideConfig = {
    slidesToShow: 6,
    slidesToScroll: 1,
    dots: false,
    autoplay: true,
    autoplaySpeed: 1000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1
        }
      },
    ]
  };

}
