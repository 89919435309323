import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import {MatInputModule} from '@angular/material/input';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { DevelopmentComponent } from './service/development/development.component';
import { UiuxComponent } from './service/uiux/uiux.component';
import { AppdevelopmentComponent } from './service/appdevelopment/appdevelopment.component';
import { QaComponent } from './service/qa/qa.component';
import { FaqComponent } from './faq/faq.component';
import { SolutionTemplateOneComponent } from './solution/sol-template-1/sol-template-1.component';
import { BlogdetailComponent } from './blog/blogdetail/blogdetail.component'
import { BlogComponent } from './blog/blog/blog.component';
import { PrivacypolicyComponent } from './contentpages/privacypolicy/privacypolicy.component';
import { ContactComponent } from './contact/contact.component'
import { HttpClientModule } from '@angular/common/http';
import { FreequoteComponent } from './modal/freequote/freequote.component';
import { HashLocationStrategy, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { FacebookModule } from 'ngx-facebook';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    DevelopmentComponent,
    UiuxComponent,
    AppdevelopmentComponent,
    QaComponent,
    FaqComponent,
    SolutionTemplateOneComponent,
    BlogdetailComponent,
    BlogComponent,
    PrivacypolicyComponent,
    ContactComponent,
    FreequoteComponent
  ],
  imports: [
    FacebookModule.forRoot(),
    BrowserModule,
    MatInputModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SlickCarouselModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [Location,{provide: LocationStrategy, useClass: PathLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
