 <!-- faq start -->
<div class="faq-main all_sec">
    <div class="conta_iner">
        <div class="title-bar">
              <h2><span class="">FAQs</span>Frequently Asked Questions</h2>
        </div>
        <div class="faq">
            <div class="panel-group" id="accordion">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <a data-toggle="collapse" data-parent="#accordion" href="#collapse1">
                            <div class="cp-main">
                                <div class="panel-title">
                                     <h4>Are your websites SEO friendly?</h4>
                                </div>
                                <div class="icon">
                                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                                </div>   
                            </div>
                        </a>
                    </div>
                <div id="collapse1" class="panel-collapse collapse in">
                    <div class="panel-body">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        </p>
                    </div>
                </div>
            </div>
            </div>
             <div class="panel-group" id="accordion2">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <a data-toggle="collapse" data-parent="#accordion" href="#collapse2">
                            <div class="cp-main">
                                <div class="panel-title">
                                     <h4>I want to redesign my existing website. Help </h4>
                                </div>
                                <div class="icon">
                                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                                </div>   
                            </div>
                        </a>
                    </div>
                <div id="collapse2" class="panel-collapse collapse in">
                    <div class="panel-body">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        </p>
                    </div>
                </div>
            </div>
            </div>

            <div class="panel-group" id="accordion3">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <a data-toggle="collapse" data-parent="#accordion3" href="#collapse3">
                            <div class="cp-main">
                                <div class="panel-title">
                                     <h4>How long does it take to design or develop a website?</h4>
                                </div>
                                <div class="icon">
                                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                                </div>   
                            </div>
                        </a>
                    </div>
                <div id="collapse3" class="panel-collapse collapse in">
                    <div class="panel-body">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        </p>
                    </div>
                </div>
            </div>
            </div>


  <div class="panel-group" id="accordion4">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <a data-toggle="collapse" data-parent="#accordion4" href="#collapse4">
                            <div class="cp-main">
                                <div class="panel-title">
                                     <h4>Do you provide maintenance </h4>
                                </div>
                                <div class="icon">
                                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                                </div>   
                            </div>
                        </a>
                    </div>
                <div id="collapse4" class="panel-collapse collapse in">
                    <div class="panel-body">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        </p>
                    </div>
                </div>
            </div>
            </div>


        </div>
    </div>
 </div>
    <!-- faq end -->