<div class="web-page">
    <app-header></app-header>
    <div class="uiux-banner">
        <div class="conta_iner">
            <div class="uiuxb-main">
                <div class="uiuxb-left">
                    <h1>We dabble your ideas in our out of the box designs!</h1>
                    <button data-toggle="modal" data-target="#getquote">
                        <figure class="mr-2"><img src="assets/images/btn-icon.png"></figure>Get a Free Quote
                    </button>
                </div>
                <div class="uiuxb-right">
                    <figure><img src="assets/images/uiux/jpg/ic_box.png"></figure>
                </div>
            </div>
        </div>
    </div>
    <!-- what we do start -->
    <section class="uiux-do all_sec">
        <div class="conta_iner">
            <div class="title-bar">
                <h2><span class="purple">Process</span>What we do ? </h2>
            </div>
            <ul>
                <li>
                    <div class="uiuxdo-feature">
                        <figure><img src="assets/images/uiux/svg/ic_ill1.svg"></figure>
                        <p>Build the right idea, keeping your requirements foremost</p>
                    </div>
                </li>
                <li>
                    <div class="uiuxdo-feature">
                        <figure><img src="assets/images/uiux/svg/ic_ill2.svg"></figure>
                        <p>Execute the idea faster, with peace of mind.</p>
                    </div>
                </li>
                <li>
                    <div class="uiuxdo-feature">
                        <figure><img src="assets/images/uiux/svg/ic_ill3.svg"></figure>
                        <p>Beautiful design, tailored for you.</p>
                    </div>
                </li>
            </ul>
        </div>
    </section>
    <!-- what we do end -->
    <!-- uiux service start -->
    <section class="uiux-service all_sec">
        <div class="conta_iner">
            <div class="title-bar">
                <h2><span class="purple">Services</span>What we provide </h2>
            </div>
            <div class="uiuxser-main">
                <div class="uiuxs-left">
                    <figure class="screen">
                        <img src="assets/images/uiux/jpg/ic_appdesign.png">
                    </figure>

                    <div class="warranty">
                        <figure>
                            <img src="assets/images/uiux/jpg/ic_stamp.png">
                        </figure>
                    </div>
                </div>
                <div class="uiuxs-right">
                    <div class="stamp"></div>
                    <h3>App <br>Designing</h3>
                    <div class="uiux_wave"></div>
                    <p>Highly user friendly designs for apps to be executed on android and iOS platforms.</p>
                    <button data-toggle="modal" data-target="#getquote">
                        <figure class="mr-2"><img src="assets/images/btn-icon.png"></figure>Get a Free Quote
                    </button>
                </div>
            </div>
        </div>
    </section>
    <!-- uiux service end -->
    <!-- uiux web service start -->
    <section class="uiux-webservice  all_sec">
        <div class="conta_iner">
            <div class="uiuxweb-main">
                <div class="uiuxweb-right">
                    <h3>Web <br>Designing</h3>
                    <div class="uiux_wave"></div>
                    <p>Every kind of website made to be run on every scale.</p>
                    <button data-toggle="modal" data-target="#getquote">
                        <figure class="mr-2"><img src="assets/images/uiux/svg/ic_send1.svg"></figure>Get a Free Quote
                    </button>
                </div>
                <div class="uiuxweb-left">
                    <figure class="">
                        <img src="assets/images/uiux/jpg/ic_laptop.png">
                    </figure>
                </div>
            </div>
            <ul class="software-design">
                <li>
                    <figure><img src="assets/images/uiux/jpg/ic_cap1.png"></figure>
                </li>
                <li>
                    <figure><img src="assets/images/uiux/jpg/ic_cap2.png"></figure>
                </li>
                <li>
                    <figure><img src="assets/images/uiux/jpg/ic_cap3.png"></figure>
                </li>
                <li>
                    <figure><img src="assets/images/uiux/jpg/ic_cap4.png"></figure>
                </li>
            </ul>
        </div>
    </section>
    <!-- uiux web service end -->
    <!-- uiux service start -->
    <section class="uiux-service brand all_sec">
        <div class="conta_iner">
            <div class="uiuxser-main">
                <div class="uiuxs-left">
                    <figure class="brand">
                        <img src="assets/images/uiux/jpg/ic_branding.png">
                    </figure>
                </div>
                <div class="uiuxs-right">
                    <h3>Branding</h3>
                    <div class="uiux_wave"></div>
                    <p>Creating brand identity with uniformity using specific patterns in typography and colors.</p>
                    <button data-toggle="modal" data-target="#getquote">
                        <figure class="mr-2"><img src="assets/images/btn-icon.png"></figure>Get a Free Quote
                    </button>
                </div>
            </div>
        </div>
    </section>
    <!-- uiux service end -->
    <!-- faq start -->


    <!-- <div class="faq-main all_sec">
        <div class="conta_iner">
            <div class="title-bar">
                <h2><span class="warning-text">FAQs</span>Frequently Asked Questions</h2>
            </div>
            <div class="faq">
                <div class="panel-group" id="accordion">
                    <div class="panel panel-default">
                        <div class="panel-heading">
                            <a data-toggle="collapse" data-parent="#accordion" href="#collapse1">
                                <div class="cp-main">
                                    <div class="panel-title">
                                        <h4>Are your websites SEO friendly?</h4>
                                    </div>
                                    <div class="icon">
                                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div id="collapse1" class="panel-collapse collapse in">
                            <div class="panel-body">
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                    veniam,
                                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel-group" id="accordion2">
                    <div class="panel panel-default">
                        <div class="panel-heading">
                            <a data-toggle="collapse" data-parent="#accordion" href="#collapse2">
                                <div class="cp-main">
                                    <div class="panel-title">
                                        <h4>I want to redesign my existing website. Help </h4>
                                    </div>
                                    <div class="icon">
                                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div id="collapse2" class="panel-collapse collapse in">
                            <div class="panel-body">
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                    veniam,
                                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="panel-group" id="accordion3">
                    <div class="panel panel-default">
                        <div class="panel-heading">
                            <a data-toggle="collapse" data-parent="#accordion3" href="#collapse3">
                                <div class="cp-main">
                                    <div class="panel-title">
                                        <h4>How long does it take to design or develop a website?</h4>
                                    </div>
                                    <div class="icon">
                                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div id="collapse3" class="panel-collapse collapse in">
                            <div class="panel-body">
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                    veniam,
                                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="panel-group" id="accordion4">
                    <div class="panel panel-default">
                        <div class="panel-heading">
                            <a data-toggle="collapse" data-parent="#accordion4" href="#collapse4">
                                <div class="cp-main">
                                    <div class="panel-title">
                                        <h4>Do you provide maintenance </h4>
                                    </div>
                                    <div class="icon">
                                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div id="collapse4" class="panel-collapse collapse in">
                            <div class="panel-body">
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                    veniam,
                                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div> -->


    <!-- faq end -->



    <app-footer></app-footer>
</div>