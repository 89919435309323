import { Component, OnInit } from '@angular/core';
import $ from "jquery";
import * as AOS from 'aos';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-uiux',
  templateUrl: './uiux.component.html',
  styleUrls: ['./uiux.component.scss']
})
export class UiuxComponent implements OnInit {

  constructor(private title: Title) {
    this.title.setTitle('Raisincode | UI/UX Designing')
  }
  ngOnInit(): void {}
}