<div class="web-page">
<app-header></app-header>
    <div class="blog-page">
        <div class="blog-banner">
            <div class="conta_iner">
                <div class="bb-main">
                    <div class="blog-left">
                        <h1>Raisincode <br>Insights</h1>
                        <div class="seach-blog">
                            <input type="text" placeholder="Search for Blogs">
                        </div>
                    </div>
                    <div class="blog-right">
                        <figure>
                            <img src="assets/images/blog/banner.svg">
                        </figure>    
                    </div>
                </div>
            </div>
        </div>
        <!-- blog-start -->
        <section class="bm_sec all_sec">
            <div class="conta_iner">
                <div class="blog-main">
                    <div class="blog-left">
                        <ul>
                            <li>
                                <div class="bb-box">
                                    <figure>
                                        <img src="assets/images/blog/blog1.jpg">
                                    </figure>
                                    <div class="bb-ontent">
                                        <h3>WWDC 2021 | Apple Unveils Latest Updates for iOS 15, iPadOS 15 and more</h3>
                                        <p>It’s that time of the year again! Apple kicked off its annual Apple Worldwide Developers Conference this year with a …</p>
                                        <button routerLink="/resources/blog/WWDC 2021">Read more ></button>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="bb-box">
                                    <figure>
                                        <img src="assets/images/blog/blog2.jpg">
                                    </figure>
                                    <div class="bb-ontent">
                                        <h3>WWDC 2021 | Apple Unveils Latest Updates for iOS 15, iPadOS 15 and more</h3>
                                        <p>It’s that time of the year again! Apple kicked off its annual Apple Worldwide Developers Conference this year with a …</p>
                                         <button routerLink="//resources/blog/WWDC 2021">Read more ></button>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="bb-box">
                                    <figure>
                                        <img src="assets/images/blog/blog3.jpg">
                                    </figure>
                                    <div class="bb-ontent">
                                        <h3>WWDC 2021 | Apple Unveils Latest Updates for iOS 15, iPadOS 15 and more</h3>
                                        <p>It’s that time of the year again! Apple kicked off its annual Apple Worldwide Developers Conference this year with a …</p>
                                          <button routerLink="//resources/blog/WWDC 2021">Read more ></button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="blog-right">
                        <div class="btag-box">
                            <div class="tag-main">
                                <div class="btag-img">
                                    <figure> <img src="assets/images/blog/tags.svg"></figure>
                                </div>
                                <div class="btag-text">
                                    <h5>TAGS</h5>
                                </div>
                            </div>
                            <ul class="tag-list">
                                <li>COVID</li>
                                <li>SOFTWARE</li>
                                <li>DESIGN</li>
                                <li>DEVELOPER</li>
                                <li>CONTENT</li>
                                <li>PODCAST</li>
                                <li>MUSIC</li>
                                <li>TEACHNOLOGY</li>
                                <li>PANEL</li>
                            </ul>
                        </div>
                        <div class="btag-box">
                            <div class="tag-main">
                                <div class="btag-img">
                                    <figure> <img src="assets/images/blog/recent.svg"></figure>
                                </div>
                                <div class="btag-text">
                                    <h5>RECENT POSTS</h5>
                                </div>
                            </div>
                            <ul class="recent-list">
                                <li>Spotify Takes on Clubhouse and Twitter Spaces with Spotify Greenroom</li>
                                <li>WWDC 2021 | Apple Unveils Latest Updates for iOS 15, iPadOS 15 and more</li>
                                <li>WWDC 2021 | Apple Unveils Latest Updates for iOS 15, iPadOS 15 and more</li>
                                <li>6 Easy Steps on How to Find Product-Market Fit Of Your App Idea/Product</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>    
        <!-- blog-end -->
        <!-- subscribe start -->
        <section class="subscribe-sec all_sec">
            <div class="conta_iner">
                <div class="bs-main">
                    <div class="bs-left">
                        <h3>Subscribe to us</h3>
                        <form>
                            <input type="text" placeholder="Enter your email">
                            <button>Send</button>
                        </form>
                    </div>
                    <div class="bs-right">
                        <figure>
                            <img src="assets/images/blog/subscribe.svg">
                        </figure>
                    </div>
                </div>
            </div>
        </section>
        <!-- subscribe end -->
    </div>
    <app-footer></app-footer>
</div>     