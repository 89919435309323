import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-appdevelopment',
  templateUrl: './appdevelopment.component.html',
  styleUrls: ['./appdevelopment.component.scss']
})
export class AppdevelopmentComponent implements OnInit {

  constructor(private title: Title) {
    this.title.setTitle('Raisincode | Mobile App Development')
  }

  ngOnInit(): void {
  }

}
