import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery'
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  clicked:boolean = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
    let logo = document.getElementById("logo-img");
    let logo2 = document.getElementById("logo-img-2");
    $(document).ready(function () {
      if ($(document).scrollTop() < 50) {
        document.getElementById("myNavbar").className = "collapse navbar-collapse";
      }
      $(window).scroll(function () {
        if ($(document).scrollTop() > 50) {
          $(".header").addClass("test");
          $("#logo-img").addClass("displaynone");
          $("#logo-img-2").removeClass("displaynone");
        } else {
          $(".header").removeClass("test");
          document.getElementById("myNavbar").className = "collapse navbar-collapse";
          $("#logo-img").removeClass("displaynone");
          $("#logo-img-2").addClass("displaynone");
        }
      });
    });
    if(this.router.url === "/contact-us" || this.router.url === "/privacy-policy" || this.router.url.indexOf("/resources/blog/") !== -1) {
      $(".header").addClass("test");
      $(document).ready(function () {
        $(window).scroll(function () {
          $(".header").addClass("test");
        });
      });
    }
  }

  changeHeader() {
    if(!this.clicked && $(document).scrollTop() < 50) {
      window.scrollTo(0,70);
    }
  }

  getClassActive(i) {
    if(i===0 && this.router.url === '/') {
      return 'active-link';
    }
    if(i===1 && (this.router.url === '/services/ui-ux-design' || this.router.url === '/services/website-development' || this.router.url === '/services/quality-assurance' || this.router.url === '/services/mobile-application-development')) {
      return 'active-link';
    }
    if(i===2 && (this.router.url === '/solutions/food-delivery-solution' || this.router.url === '/solutions/grocery-delivery-solution' || this.router.url === '/solutions/car-booking-solution' || this.router.url === '/solutions/ondemand-services-solution')) {
      return 'active-link';
    }
  }

}

