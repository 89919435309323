<div class="web-page">
    <app-header></app-header>
    <div class="fdp">
        <div [class]="getClass(1)">
            <div class="conta_iner">
                <div class="fdbanner-main">
                    <div [class]="getClass(2)">
                        <h1>{{result.data.sol0001}}</h1>
                        <p>{{result.data.sol0002}}</p>
                        <button class="" data-toggle="modal" data-target="#getquote">
                            <figure class="mr-2">
                                <img [src]="result.img.img0001 || ''" alt="img">
                            </figure>Get a Free Quote
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div [class]="getClass(3)">
            <figure>
                <img [src]="result.img.img0002 || ''" alt="img">
            </figure>
        </div>
    </div>
    <!-- food delivery solution start -->
    <section class="food-delivery">
        <div class="conta_iner">
            <div class="title-bar">
                <h2><span [class]="getClass(5)">{{result.data.sol0003}}</span>{{result.data.sol0004}}</h2>
            </div>
            <div class="p-follow">
                <ul [class]="getClass(6)">
                    <li>
                        <a class="active" data-toggle="tab" href="#tab1">{{result.data.sol0005}} </a>
                    </li>
                    <li>
                        <a data-toggle="tab" href="#tab2">{{result.data.sol0006}} </a>
                    </li>
                    <li>
                        <a data-toggle="tab" href="#tab3">{{result.data.sol0007}} </a>
                    </li>
                </ul>
                <div class="tab-content">
                    <div id="tab1" class="tab-pane active">
                        <div class="tab-inner">
                            <div class="process-img">
                                <figure>
                                    <img [src]="result.img.img0003 || ''" alt="img">
                                </figure>
                            </div>
                            <div [class]="getClass(7)">
                                <p>{{result.data.sol0008}}</p>
                                <div [class]="getClass(8)">
                                    <button class="" data-toggle="modal" data-target="#getquote">
                                        <figure class="mr-2">
                                            <img [src]="result.img.img0004 || ''" alt="img">
                                        </figure>Get a Free Quote
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="tab2" class="tab-pane">
                        <div class="tab-inner">
                            <div class="process-img">
                                <figure>
                                    <img [src]="result.img.img0005 || ''" alt="img">
                                </figure>
                            </div>
                            <div [class]="getClass(7)">
                                <p>{{result.data.sol0009}}</p>
                                <div [class]="getClass(8)">
                                    <button class="" data-toggle="modal" data-target="#getquote">
                                        <figure class="mr-2">
                                            <img [src]="result.img.img0006 || ''" alt="img">
                                        </figure>Get a Free Quote
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="tab3" class="tab-pane">
                        <div class="tab-inner">
                            <div class="process-img">
                                <figure>
                                    <img [src]="result.img.img0007 || ''" alt="img">
                                </figure>
                            </div>
                            <div [class]="getClass(7)">
                                <p>{{result.data.sol00010}}</p>
                                <div [class]="getClass(8)">
                                    <button class="" data-toggle="modal" data-target="#getquote">
                                        <figure class="mr-2">
                                            <img [src]="result.img.img0008 || ''" alt="img">
                                        </figure>Get a Free Quote
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- food delivery solution end -->
    <!-- whole package start -->
    <section [class]="getClass(9)">
        <div class="conta_iner">
            <div class="title-bar">
                <h2><span [class]="getClass(5)">{{result.data.sol00011}}</span>{{result.data.sol00012}}</h2>
            </div>
            <div [class]="getClass(14)">
                <div class="dashboard-img">
                    <div class="dashpimg">
                        <figure class="dashboard-img">
                            <img [src]="result.img.img0009 || ''" alt="img">
                        </figure>
                        <figure class="ms-left">
                            <img [src]="result.img.img00010 || ''" alt="img">
                        </figure>
                        <figure class="ms-right">
                            <img [src]="result.img.img00011 || ''" alt="img">
                        </figure>
                    </div>
                </div>
                <ul>
                    <li>
                        <h4>{{result.data.sol00013}}</h4>
                        <p>{{result.data.sol00014}}</p>
                    </li>
                    <li>
                        <h4>{{result.data.sol00015}}</h4>
                        <p>{{result.data.sol00016}}</p>
                    </li>
                    <li>
                        <h4>{{result.data.sol00017}}</h4>
                        <p>{{result.data.sol00018}}</p>
                    </li>
                </ul>
                <div [class]="getClass(8)">
                    <button class="" data-toggle="modal" data-target="#getquote">
                        <figure class="mr-2">
                            <img [src]="result.img.img00012 || ''" alt="img">
                        </figure>Get a Free Quote
                    </button>
                </div>
            </div>
        </div>
    </section>
    <!-- whole package end -->
    <!-- solution feture start -->
    <section [class]="getClass(12)">
        <div class="conta_iner">
            <div class="title-bar">
                <div class="title-bar">
                    <h2><span [class]="getClass(5)">{{result.data.sol00019}}</span>{{result.data.sol00020}}</h2>
                </div>
            </div>
            <div class="solutionf-row">
                <div [class]="getClass(13)">
                    <figure>
                        <img [src]="result.img.img00013 || ''" alt="img">
                    </figure>
                </div>
                <div class="sfr-right">
                    <h4>{{result.data.sol00021}}</h4>
                    <ul>
                        <li>
                            <div [class]="getClass(11)">
                                <div [class]="getClass(10)">
                                </div>
                                <div class="cus-right">
                                    <h5>{{result.data.sol00022}}</h5>
                                    <p>{{result.data.sol00023}}</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div [class]="getClass(11)">
                                <div [class]="getClass(10)">
                                </div>
                                <div class="cus-right">
                                    <h5>{{result.data.sol00024}}</h5>
                                    <p>{{result.data.sol00025}}</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div [class]="getClass(11)">
                                <div [class]="getClass(10)">
                                </div>
                                <div class="cus-right">
                                    <h5>{{result.data.sol00026}}</h5>
                                    <p>{{result.data.sol00027}}</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div [class]="getClass(11)">
                                <div [class]="getClass(10)">
                                </div>
                                <div class="cus-right">
                                    <h5>{{result.data.sol00028}}</h5>
                                    <p>{{result.data.sol00029}}</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div [class]="getClass(11)">
                                <div [class]="getClass(10)">
                                </div>
                                <div class="cus-right">
                                    <h5>{{result.data.sol00030}}</h5>
                                    <p>{{result.data.sol00031}}</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="solutionf-row reverse">
                <div [class]="getClass(13)">
                    <figure>
                        <img [src]="result.img.img00014 || ''" alt="img">
                    </figure>
                </div>
                <div class="sfr-right">
                    <h4>{{result.data.sol00032}}</h4>
                    <ul>
                        <li>
                            <div [class]="getClass(11)">
                                <div [class]="getClass(10)">
                                </div>
                                <div class="cus-right">
                                    <h5>{{result.data.sol00033}}</h5>
                                    <p>{{result.data.sol00034}}</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div [class]="getClass(11)">
                                <div [class]="getClass(10)">
                                </div>
                                <div class="cus-right">
                                    <h5>{{result.data.sol00035}}</h5>
                                    <p>{{result.data.sol00036}}</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div [class]="getClass(11)">
                                <div [class]="getClass(10)">
                                </div>
                                <div class="cus-right">
                                    <h5>{{result.data.sol00037}}</h5>
                                    <p>{{result.data.sol00038}}</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div [class]="getClass(11)">
                                <div [class]="getClass(10)">
                                </div>
                                <div class="cus-right">
                                    <h5>{{result.data.sol00039}}</h5>
                                    <p>{{result.data.sol00040}}</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div [class]="getClass(11)">
                                <div [class]="getClass(10)">
                                </div>
                                <div class="cus-right">
                                    <h5>{{result.data.sol00041}}</h5>
                                    <p>{{result.data.sol00042}}</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="solutionf-row">
                <div [class]="getClass(13)">
                    <figure>
                        <img [src]="result.img.img00015 || ''" alt="img">
                    </figure>
                </div>
                <div class="sfr-right">
                    <h4>{{result.data.sol00043}}</h4>
                    <ul>
                        <li>
                            <div [class]="getClass(11)">
                                <div [class]="getClass(10)">
                                </div>
                                <div class="cus-right">
                                    <h5>{{result.data.sol00044}}</h5>
                                    <p>{{result.data.sol00045}}</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div [class]="getClass(11)">
                                <div [class]="getClass(10)">
                                </div>
                                <div class="cus-right">
                                    <h5>{{result.data.sol00046}}</h5>
                                    <p>{{result.data.sol00047}}</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div [class]="getClass(11)">
                                <div [class]="getClass(10)">
                                </div>
                                <div class="cus-right">
                                    <h5>{{result.data.sol00048}}</h5>
                                    <p>{{result.data.sol00049}}</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div [class]="getClass(11)">
                                <div [class]="getClass(10)">
                                </div>
                                <div class="cus-right">
                                    <h5>{{result.data.sol00050}}</h5>
                                    <p>{{result.data.sol00051}}</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div [class]="getClass(11)">
                                <div [class]="getClass(10)">
                                </div>
                                <div class="cus-right">
                                    <h5>{{result.data.sol00052}}</h5>
                                    <p>{{result.data.sol00053}}</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <!-- solution feture end -->
    <!-- sc delivery start -->
    <section [class]="getClass(4)">
        <div class="conta_iner">
            <div class="sc-main">
                <!-- <div class="sc-left">
                    <figure>
                        <img src="assets/images/fooddelivery/sc.jpg">
                    </figure>    
                </div> -->
                <div class="sc-left">
                    <h3>{{result.data.sol00054}}</h3>
                    <p>{{result.data.sol00055}}<span>{{result.data.sol00056}}</span></p>
                    <button class="">
                        <figure class="mr-2" data-toggle="modal" data-target="#getquote">
                            <img [src]="result.img.img00016 || ''" alt="img">
                        </figure>Get a Free Quote
                    </button>
                </div>
            </div>
        </div>
    </section>
    <!-- sc delivery end -->
    <!-- faq start -->


    <!-- <div class="faq-main all_sec">
        <div class="conta_iner">
            <div class="title-bar">
                <h2><span [class]="getClass(5)">FAQs</span>Frequently Asked Questions</h2>
            </div>
            <div class="faq">
                <div class="panel-group" id="accordion">
                    <div class="panel panel-default">
                        <div class="panel-heading">
                            <a data-toggle="collapse" data-parent="#accordion" href="#collapse1">
                                <div class="cp-main">
                                    <div class="panel-title">
                                        <h4>Are your websites SEO friendly?</h4>
                                    </div>
                                    <div class="icon">
                                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div id="collapse1" class="panel-collapse collapse in">
                            <div class="panel-body">
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                    veniam,
                                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel-group" id="accordion2">
                    <div class="panel panel-default">
                        <div class="panel-heading">
                            <a data-toggle="collapse" data-parent="#accordion" href="#collapse2">
                                <div class="cp-main">
                                    <div class="panel-title">
                                        <h4>I want to redesign my existing website. Help </h4>
                                    </div>
                                    <div class="icon">
                                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div id="collapse2" class="panel-collapse collapse in">
                            <div class="panel-body">
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                    veniam,
                                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="panel-group" id="accordion3">
                    <div class="panel panel-default">
                        <div class="panel-heading">
                            <a data-toggle="collapse" data-parent="#accordion3" href="#collapse3">
                                <div class="cp-main">
                                    <div class="panel-title">
                                        <h4>How long does it take to design or develop a website?</h4>
                                    </div>
                                    <div class="icon">
                                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div id="collapse3" class="panel-collapse collapse in">
                            <div class="panel-body">
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                    veniam,
                                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="panel-group" id="accordion4">
                    <div class="panel panel-default">
                        <div class="panel-heading">
                            <a data-toggle="collapse" data-parent="#accordion4" href="#collapse4">
                                <div class="cp-main">
                                    <div class="panel-title">
                                        <h4>Do you provide maintenance </h4>
                                    </div>
                                    <div class="icon">
                                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div id="collapse4" class="panel-collapse collapse in">
                            <div class="panel-body">
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                    veniam,
                                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div> -->


    <!-- faq end -->
    
    <!-- footer start -->
    <app-footer></app-footer>
    <!-- footer end -->
</div>