<div class="web-page">
    <app-header></app-header>

    <div class="home-page">
        <div class="conta_iner">
            <div class="banner-main">
                <div class="bnner-left">
                    <!-- <div class="bnner-left" data-aos="fade-right"> -->
                    <h1>Professional <br> transformation
                        <span>Seasoned with innovation.</span>
                    </h1>
                    <p>
                        We are a bunch of enthusiastic people who aim at bringing a change in IT services industry by
                        continuous adaptation of modern analytics and innovation.

                        We focus on providing a platform that can look as good as it can function. After all the looks
                        matter too !

                        Get in touch with us and let's make your vision a reality.
                    </p>
                    

                </div>
                <div class="bnner-right">

                    <form>
                        <ul>
                            <li>
                                <mat-form-field [class]="errors.name.required?'error-form':''" [floatLabel]="'never'">
                                    <input matInput type="text" placeholder="Name" name="name" [ngModelOptions]="{standalone: true}" [(ngModel)]="data.name" (ngModelChange)="checkValidity()">
                                    <span *ngIf="errors.name.required" class="error-msg">*required</span>
                                </mat-form-field>
                            </li>
                            <li>
                                <mat-form-field [class]="errors.email.required?'error-form':''" [floatLabel]="'never'">
                                    <input matInput type="email" placeholder="Email" name="name" [ngModelOptions]="{standalone: true}" [(ngModel)]="data.email" (ngModelChange)="checkValidity()">
                                    <span *ngIf="errors.email.required" class="error-msg">*required</span>
                                    <span *ngIf="errors.email.valid" class="error-msg">*invalid email</span>
                                </mat-form-field>
                            </li>
                            <li>
                                <mat-form-field [class]="errors.phoneNo.required?'error-form':''" [floatLabel]="'never'">
                                    <input matInput type="number" placeholder="Contact" name="name" [ngModelOptions]="{standalone: true}" [(ngModel)]="data.phoneNo" (ngModelChange)="checkValidity()">
                                    <span *ngIf="errors.phoneNo.required" class="error-msg">*required</span>
                                </mat-form-field>
                            </li>
                            <li>
                                <mat-form-field [class]="errors.budget.required?'error-form':''" [floatLabel]="'never'">
                                    <input matInput type="number" placeholder="Budget" name="name" [ngModelOptions]="{standalone: true}" [(ngModel)]="data.budget" (ngModelChange)="checkValidity()">
                                    <span *ngIf="errors.budget.required" class="error-msg">*required</span>
                                </mat-form-field>
                            </li>
                            <li>
                                <mat-form-field [class]="errors.requirements.required?'example-full-width error-form':'example-full-width'" [floatLabel]="'never'">
                                    <textarea matInput placeholder="Requirement" [ngModelOptions]="{standalone: true}" [(ngModel)]="data.requirements" (ngModelChange)="checkValidity()"></textarea>
                                    <span *ngIf="errors.requirements.required" class="error-msg">*required</span>
                                </mat-form-field>
                            </li>
                        </ul>
                        <button class="btn btn-primary text-white" (click)="generateLead()">
                            <figure><img src="assets/images/btn-icon.png"></figure>
                            Get a Free Quote
                        </button>
                    </form>

                    <!-- <div class="contact-right">
                        <h5>Info</h5>

                        <ul>
                            <li>
                                <div class="info-main">
                                    <div class="info-left">
                                        <figure>
                                            <img src="assets/images/contact/mail.svg">
                                        </figure>
                                    </div>
                                    <div class="info-right">
                                        <p><a href="mailto:support@raisincode.com">support@raisincode.com</a> <small>
                                                (For any queries)</small></p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="info-main">
                                    <div class="info-left">
                                        <figure>
                                            <img src="assets/images/contact/mail.svg">
                                        </figure>
                                    </div>
                                    <div class="info-right">
                                        <p><a href="mailto:contact@raisincode.com">contact@raisincode.com</a> <small>
                                                (To discuss an idea)</small></p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="info-main">
                                    <div class="info-left">
                                        <figure>
                                            <img src="assets/images/contact/call.svg">
                                        </figure>
                                    </div>
                                    <div class="info-right">
                                        <p><a href="{{'tel:'+'+91-98727-68848'}}">+91-98727-68848</a></p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="info-main">
                                    <div class="info-left">
                                        <figure>
                                            <img src="assets/images/contact/address.svg">
                                        </figure>
                                    </div>
                                    <div class="info-right">
                                        <p>Mohali, India</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="info-main">
                                    <div class="info-left">
                                        <figure>
                                            <img src="assets/images/contact/clock.svg">
                                        </figure>
                                    </div>
                                    <div class="info-right">
                                        <p>Always Open</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div class="wave-btm">
                            <figure>
                                <img src="assets/images/contact/wave.svg">
                            </figure>
                        </div>
                    </div> -->
                

                </div>
            </div>
            <!-- <div class="bannerright"></div> -->
        </div>
    </div>

    <div class="logo-slider">
        <div class="conta_iner">
            <div class="logo-inner">
                <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
                    <div ngxSlickItem *ngFor="let slide of slides" class="slide">
                        <img src="{{ slide.img }}" alt="" width="100%">
                    </div>
                </ngx-slick-carousel>
            </div>
        </div>
    </div>


    <section class="services" id="services">
        <div class="conta_iner">
            <div class="title-bar" data-aos="fade-up">
                <h2><span>SERVICES</span>Services we Offer</h2>
            </div>
            <div class="service-feature" data-aos="fade-up">
                <ul>
                    <li>
                        <div class="s-feture" routerLink="/services/quality-assurance">
                            <figure>
                                <img src="assets/images/c_webdesign.jpg">
                            </figure>
                            <h4>Quality Assurance </h4>
                        </div>
                    </li>
                    <li>
                        <div class="s-feture" routerLink="/services/ui-ux-design">
                            <figure>
                                <img src="assets/images/uiux.png">
                            </figure>
                            <h4>UI/UX </h4>
                        </div>
                    </li>
                    <li>
                        <div class="s-feture" routerLink="/services/website-development">
                            <figure>
                                <img src="assets/images/develop.jpg">
                            </figure>
                            <h4>Web Development </h4>
                        </div>
                    </li>
                    <li>
                        <div class="s-feture" routerLink="/services/mobile-application-development">
                            <figure>
                                <img src="assets/images/appdevelop.jpg">
                            </figure>
                            <h4>App Development </h4>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </section>


    <section class="stay-connect" data-aos="fade-up">
        <div class="conta_iner">
            <div class="title-bar">
                <h2 class="text-white">Stay connected with Raisincode</h2>
                <p class="text-white text-center">Stay connected with us and get answers about all your queries in just
                    few
                    hours.</p>
                <button class="btn btn-primary" data-toggle="modal" data-target="#getquote">Contact Us</button>
            </div>
        </div>
    </section>



    <section class="choose-us">
        <div class="conta_iner">
            <div class="title-bar" data-aos="fade-up">
                <h2><span>WHY CHOOSE US</span>What’s special about us ?</h2>
            </div>
            <div class="choose-f">
                <ul>
                    <li data-aos="fade-right">
                        <div class="choose-main">
                            <div class="choose-left">
                                <figure>
                                    <img src="assets/images/ic_design.png">
                                </figure>
                            </div>
                            <div class="choose-right">
                                <h5>Eye catching designs</h5>
                                <p>Our inhouse design studio creates designs according to the latest market trends and
                                    offcourse
                                    following what the client wants.
                                <p>
                            </div>
                        </div>
                    </li>
                    <li data-aos="fade-left">
                        <div class="choose-main">
                            <div class="choose-left">
                                <figure>
                                    <img src="assets/images/web-design.png">
                                </figure>
                            </div>
                            <div class="choose-right">
                                <h5>Highly simplified code</h5>
                                <p>We follow a very structured approach segmented by modelues to write the code keeping
                                    all
                                    the coding conventions in mind so that your solution is beautiful inside out !
                                <p>
                            </div>
                        </div>
                    </li>
                    <li data-aos="fade-right">
                        <div class="choose-main">
                            <div class="choose-left">
                                <figure>
                                    <img src="assets/images/ic_manage.png">
                                </figure>
                            </div>
                            <div class="choose-right">
                                <h5>Effective management</h5>
                                <p>Highly scheduled management which knows how to plan things and put things into place
                                    while keeping in mind all three constraints (i.e)time, scope and cost which
                                    collectively
                                    gives quality.
                                <p>
                            </div>
                        </div>
                    </li>
                    <li data-aos="fade-left">
                        <div class="choose-main">
                            <div class="choose-left">
                                <figure>
                                    <img src="assets/images/ic_time.png">
                                </figure>
                            </div>
                            <div class="choose-right">
                                <h5>Fast delivery</h5>
                                <p>Following all the steps which lead to successfulll deliverance of milestaones ensures
                                    delivery on time.
                                <p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </section>


    <section class="solution pt-0" id="solution">
        <div class="conta_iner">
            <div class="title-bar" data-aos="fade-up">
                <h2><span>SOLUTIONS</span>Solutions we provide</h2>
            </div>
            <div class="solution-img" data-aos="fade-up">
                <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig1">
                    <div ngxSlickItem *ngFor="let slide1 of slides1" class="slide" [routerLink]="slide1.route">
                        <figure class="solution-img"><img src="{{ slide1.img }}" alt="" width="100%"></figure>
                        <div class="text-solution" routerLink="/fooddelivery">
                            <figure class="smal-img"><img src="{{ slide1.img2 }}" alt="" width="100%"></figure>
                            <h5>{{ slide1.text }}</h5>
                        </div>
                    </div>
                </ngx-slick-carousel>
                <!-- <div class="left-right-img">
                <figure class="left"><img src="assets/images/ic_back.png"></figure>
                <figure class="right"><img src="assets/images/ic_forward.png"></figure>
            </div> -->
            </div>
        </div>
    </section>


    <section class="work-main" id="work">
        <div class="conta_iner">
            <div class="title-bar">
                <h2><span>OUR WORK</span>End to end solutions</h2>
            </div>

            <div class="work-img">
                <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig3">
                    <div ngxSlickItem *ngFor="let slide3 of slides3" class="slide3">
                        <figure class="work-img">
                            <img src="{{ slide3.img }}" alt="" width="100%">
                        </figure>
                    </div>
                </ngx-slick-carousel>
            </div>
        </div>
    </section>



    <section class="about">
        <div class="conta_iner">
            <div class="about-main">
                <div class="about-left" data-aos="fade-right">
                    <ul>
                        <li>
                            <figure>
                                <img src="assets/images/ic_aboutus1.png">
                            </figure>
                        </li>
                    </ul>
                </div>
                <div class="about-mid" data-aos="fade-right">
                    <ul>
                        <li>
                            <div class="about-feature">
                                <div class="aboutf-left">
                                    <figure>
                                        <img src="assets/images/ic_quality.svg">
                                    </figure>
                                </div>
                                <div class="aboutf-right">
                                    <h5>Quality</h5>
                                    <p>
                                        We are well-versed in a variety of operating systems, networks, and databases.
                                        We work with just about any technology that a small business would encounter. We
                                        use this expertise to help customers with small to mid-sized projects.
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="about-feature">
                                <div class="aboutf-left">
                                    <figure>
                                        <img src="assets/images/ic_team.svg">
                                    </figure>
                                </div>
                                <div class="aboutf-right">
                                    <h5>Professional Team</h5>
                                    <p>
                                        Do you spend most of your IT budget on maintaining your current system? Many
                                        companies find that constant maintenance eats into their budget for new
                                        technology. By outsourcing your IT management to us, you can focus on what you
                                        do best--running your business.
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="about-feature">
                                <div class="aboutf-left">
                                    <figure>
                                        <img src="assets/images/ic_price.svg">
                                    </figure>
                                </div>
                                <div class="aboutf-right">
                                    <h5>Reasonable Prices</h5>
                                    <p>
                                        The world of technology can be fast-paced and scary. That's why our goal is to
                                        provide an experience that is tailored to your company's needs. No matter the
                                        budget, we pride ourselves on providing professional customer service. We
                                        guarantee you will be satisfied with our work.
                                    </p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="about-right" data-aos="fade-left">
                    <div class="title-bar">
                        <h2 class="text-left"><span>ABOUT US</span>Who we are</h2>
                        <p>
                            We are a bunch of enthusiastic people who aim at bringing a change in IT services industry
                            by continuous adaptation of modern analytics and innovation.
                        </p>
                        <p>
                            We focus on providing a platform that can look as good as it can function. After all the
                            looks matter too !
                        <p>
                            Get in touch with us and let's make your vision a reality.
                        </p>

                    </div>
                </div>
            </div>
        </div>
    </section>




    <section class="testimonial">
        <div class="conta_iner">
            <div class="title-bar" data-aos="fade-right">
                <h2 class="text-left"><span>SOCIAL MEDIA</span>Stay <br>connected</h2>
            </div>

            <div class="solution-img" data-aos="fade-left">
                
                <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig2">
                    <!-- <div ngxSlickItem *ngFor="let slides2 of slides2" class="slide">
                        <div class="quote-box">
                            <figure class="left-qut"> <img src="{{ slides2.img }}" alt="" width="100%"></figure>
                            <p>{{slides2.para}}</p>
                            <figure class="right-qut"> <img src="{{ slides2.img0 }}" alt="" width="100%"></figure>
                        </div>
                    </div> -->
                    <div ngxSlickItem *ngFor="let post of posts" class="slide">
                        <div class="quote-box">
                            <figure> <img [src]="post.post" alt="" width="100%"></figure>
                            <div class="footer-right">
                                <ul class="social-media">
                                    <li>
                                        <figure>
                                            <a href="https://www.facebook.com/raisincode" target="_blank">
                                                <img class="facebook" src="assets/images/ic_fb.svg">
                                            </a>
                                        </figure>
                                    </li>
                                    <li>
                                        <figure>
                                            <a href="https://www.instagram.com/raisincode?r=nametag" target="_blank">
                                                <img class="insta" src="assets/images/ic_insta.svg">
                                            </a>
                                        </figure>
                                    </li>
                                    <li>
                                        <figure>
                                            <a href="https://www.linkedin.com/company/raisincode" target="_blank">
                                                <img class="linkedin" src="assets/images/ic_linkedin.svg">
                                            </a>
                                        </figure>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </ngx-slick-carousel>


                <!-- <div class="testimonial-icon">
                    <figure class="left"><img src="assets/images/ic_left.png"></figure>
                    <figure class="right"><img src="assets/images/ic_right.png"></figure>
                </div> -->
            </div>
        </div>
    </section>






    <app-footer></app-footer>
</div>