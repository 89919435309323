<footer>
    <div class="conta_iner">
        <div class="footer">
            <div class="footer-left">
                <div class="footer-logo">
                    <figure>
                        <img src="../../assets/images/ic_logo.png">
                    </figure>
                </div>
                <div class="footer-links">
                    <h5>Quick Links</h5>
                    <ul>
                        <!-- <li>Resources</li> -->
                        <li data-toggle="modal" data-target="#getquote">Contact Us</li>
                        <!-- <li>Design Studio</li> -->
                        <li routerLink="/privacy-policy">Privacy Policy</li>
                    </ul>
                </div>
                <div class="footer-links">
                    <h5>Contact Us</h5>
                    <ul>
                        <li>
                            <p><span>Email</span><a href="mailto:contact@raisincode.com">contact@raisincode.com</a></p>
                        </li>
                        <li>
                            <p><span>Phone</span><a href="{{'tel:'+'+91-98727-68848'}}">+91-98727-68848</a></p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="footer-right">
                <ul class="social-media">
                    <li>
                        <figure>
                            <a href="https://www.facebook.com/raisincode" target="_blank">
                                <img class="facebook" src="assets/images/ic_fb.svg">
                            </a>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <a href="https://www.instagram.com/raisincode?r=nametag" target="_blank">
                                <img class="insta" src="assets/images/ic_insta.svg">
                            </a>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <a href="https://www.linkedin.com/company/raisincode" target="_blank">
                                <img class="linkedin" src="assets/images/ic_linkedin.svg">
                            </a>
                        </figure>
                    </li>
                </ul>
            </div>
        </div><br/>
        <div class="fb-like" data-href="https://developers.facebook.com/docs/plugins/" data-width="" data-layout="button" data-action="like" data-size="small" data-share="true"></div>
    </div>
</footer>
<div class="pre-footer">
    <p>2021 Raisincode™ | All rights reserved</p>
</div>

<div class="whats-up">
    <figure>
        <a href="https://wa.me/message/RYN46ETC42QXC1" target="_blank">
            <img src="assets/images/ic_wa.svg">
        </a>
    </figure>
</div>

<!-- <div class="chat-us">
    <button data-toggle="modal" data-target="#getquote">Get In Touch!</button>
</div> -->

<!-- Modal -->
<div id="getquote" class="modal fade" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-body">
                <!-- <div class="mdl-img">
                    <figure>
                        <img src="assets/images/modal.svg">
                    </figure>
                </div>
                <h4>Get a free quote</h4>
                <p>Lorem ipsum lorem ipsum lorem ipsum</p> -->

                <!-- <form>
                    <ul>
                        <li>
                            <mat-form-field [floatLabel]="'never'">
                                <input matInput type="text" placeholder="Name" name="name">
                            </mat-form-field>
                        </li>
                        <li>
                            <mat-form-field [floatLabel]="'never'">
                                <input matInput type="email" placeholder="Email" name="name">
                            </mat-form-field>
                        </li>
                        <li>
                            <mat-form-field [floatLabel]="'never'">
                                <input matInput type="number" placeholder="Contact" name="name">
                            </mat-form-field>
                        </li>
                        <li>
                            <mat-form-field [floatLabel]="'never'">
                                <input matInput type="text" placeholder="Budget" name="name">
                            </mat-form-field>
                        </li>
                        <li>
                            <mat-form-field class="example-full-width" [floatLabel]="'never'">
                                <textarea matInput placeholder="Requirement"></textarea>
                            </mat-form-field>
                        </li>
                    </ul>
                    <div class="sub-btn"> <button class="btn">
                            Submit
                        </button>
                    </div>
                </form> -->
                
                <div class="contact-right">
                    <h5>Info</h5>

                    <ul>
                        <li>
                            <div class="info-main">
                                <div class="info-left">
                                    <figure>
                                        <img src="assets/images/contact/mail.svg">
                                    </figure>
                                </div>
                                <div class="info-right">
                                    <p><a href="mailto:support@raisincode.com">support@raisincode.com</a> <small>
                                            (For any queries)</small></p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="info-main">
                                <div class="info-left">
                                    <figure>
                                        <img src="assets/images/contact/mail.svg">
                                    </figure>
                                </div>
                                <div class="info-right">
                                    <p><a href="mailto:contact@raisincode.com">contact@raisincode.com</a> <small>
                                            (To discuss an idea)</small></p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="info-main">
                                <div class="info-left">
                                    <figure>
                                        <img src="assets/images/contact/call.svg">
                                    </figure>
                                </div>
                                <div class="info-right">
                                    <p><a href="{{'tel:'+'+91-98727-68848'}}">+91-98727-68848</a></p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="info-main">
                                <div class="info-left">
                                    <figure>
                                        <img src="assets/images/contact/address.svg">
                                    </figure>
                                </div>
                                <div class="info-right">
                                    <p>Mohali, India</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="info-main">
                                <div class="info-left">
                                    <figure>
                                        <img src="assets/images/contact/clock.svg">
                                    </figure>
                                </div>
                                <div class="info-right">
                                    <p>Always Open</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="wave-btm">
                        <figure>
                            <img src="assets/images/contact/wave.svg">
                        </figure>
                    </div>
                </div>
                
            </div>

        </div>

    </div>
</div>