import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router } from '@angular/router';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-sol-template-1',
  templateUrl: './sol-template-1.component.html',
  styleUrls: ['./sol-template-1.component.scss']
})
export class SolutionTemplateOneComponent implements OnInit {
  result: {
    data: {
      sol0001: string,
      sol0002: string,
      sol0003: string,
      sol0004: string,
      sol0005: string,
      sol0006: string,
      sol0007: string,
      sol0008: string,
      sol0009: string,
      sol00010: string,
      sol00011: string,
      sol00012: string,
      sol00013: string,
      sol00014: string,
      sol00015: string,
      sol00016: string,
      sol00017: string,
      sol00018: string,
      sol00019: string,
      sol00020: string,
      sol00021: string,
      sol00022: string,
      sol00023: string,
      sol00024: string,
      sol00025: string,
      sol00026: string,
      sol00027: string,
      sol00028: string,
      sol00029: string,
      sol00030: string,
      sol00031: string,
      sol00032: string,
      sol00033: string,
      sol00034: string,
      sol00035: string,
      sol00036: string,
      sol00037: string,
      sol00038: string,
      sol00039: string,
      sol00040: string,
      sol00041: string,
      sol00042: string,
      sol00043: string,
      sol00044: string,
      sol00045: string,
      sol00046: string,
      sol00047: string,
      sol00048: string,
      sol00049: string,
      sol00050: string,
      sol00051: string,
      sol00052: string,
      sol00053: string,
      sol00054: string,
      sol00055: string,
      sol00056: string
    },
    img: {
      img0001: string,
      img0002: string,
      img0003: string,
      img0004: string,
      img0005: string,
      img0006: string,
      img0007: string,
      img0008: string,
      img0009: string,
      img00010: string,
      img00011: string,
      img00012: string,
      img00013: string,
      img00014: string,
      img00015: string,
      img00016: string
    }
  } = {
      data: {
        sol0001: "",
        sol0002: "",
        sol0003: "",
        sol0004: "",
        sol0005: "",
        sol0006: "",
        sol0007: "",
        sol0008: "",
        sol0009: "",
        sol00010: "",
        sol00011: "",
        sol00012: "",
        sol00013: "",
        sol00014: "",
        sol00015: "",
        sol00016: "",
        sol00017: "",
        sol00018: "",
        sol00019: "",
        sol00020: "",
        sol00021: "",
        sol00022: "",
        sol00023: "",
        sol00024: "",
        sol00025: "",
        sol00026: "",
        sol00027: "",
        sol00028: "",
        sol00029: "",
        sol00030: "",
        sol00031: "",
        sol00032: "",
        sol00033: "",
        sol00034: "",
        sol00035: "",
        sol00036: "",
        sol00037: "",
        sol00038: "",
        sol00039: "",
        sol00040: "",
        sol00041: "",
        sol00042: "",
        sol00043: "",
        sol00044: "",
        sol00045: "",
        sol00046: "",
        sol00047: "",
        sol00048: "",
        sol00049: "",
        sol00050: "",
        sol00051: "",
        sol00052: "",
        sol00053: "",
        sol00054: "",
        sol00055: "",
        sol00056: ""
      },
      img: {
        img0001: "",
        img0002: "",
        img0003: "",
        img0004: "",
        img0005: "",
        img0006: "",
        img0007: "",
        img0008: "",
        img0009: "",
        img00010: "",
        img00011: "",
        img00012: "",
        img00013: "",
        img00014: "",
        img00015: "",
        img00016: ""
      }
    };

  type: number;

  capitalize(str:string) {
    const lower = str.toLowerCase();
    return str.charAt(0).toUpperCase() + lower.slice(1);
  }

  constructor(private httpClient: HttpClient, private router: Router, private route: ActivatedRoute, private title: Title) {
    this.route.params.subscribe(res => {
      this.title.setTitle('Raisincode | '+ this.capitalize(res.id.split('-')[0])+' '+this.capitalize(res.id.split('-')[1])+' '+this.capitalize(res.id.split('-')[2]));

      // this.type = parseInt(res.type) || 4;
      this.httpClient.get("assets/scripts/solutions/" + res.id + ".json").subscribe((data: any) => {
        this.result = data;
      });

      res.id === "food-delivery-solution" && (this.type = 1);
      res.id === "grocery-delivery-solution" && (this.type = 2);
      res.id === "car-booking-solution" && (this.type = 3);
      res.id === "ondemand-services-solution" && (this.type = 4);
console.log(this.type)
      // this.type === 1 && this.httpClient.get("assets/solutions/fooddelivery.json").subscribe((data: any) => {
      //   this.result = data;
      //   console.log(this.result)
      // });
      // this.type === 2 && this.httpClient.get("assets/solutions/grocerydelivery.json").subscribe((data: any) => {
      //   this.result = data;
      //   console.log(this.result)
      // });
      // this.type === 3 && this.httpClient.get("assets/solutions/cabbooking.json").subscribe((data: any) => {
      //   this.result = data;
      //   console.log(this.result)
      // });
      // this.type === 4 && this.httpClient.get("assets/solutions/ondemand.json").subscribe((data: any) => {
      //   this.result = data;
      //   console.log(this.result)
      // });
    });
  }

  ngOnInit(): void {
  }

  getClass(id): string {
    if (id === 1 && this.type === 1) return "fd-back fd-banner";
    if (id === 1 && this.type === 2) return "gd-back fd-banner";
    if (id === 1 && this.type === 3) return "cb-back fd-banner";
    if (id === 1 && this.type === 4) return "od-back fd-banner";

    if (id === 2 && this.type === 1) return "fd-color-scheme fdbanner-left";
    if (id === 2 && this.type === 2) return "gd-color-scheme fdbanner-left";
    if (id === 2 && this.type === 3) return "cb-color-scheme fdbanner-left";
    if (id === 2 && this.type === 4) return "od-color-scheme fdbanner-left";

    if (id === 3 && this.type === 1) return "fd-circle fdbanner-right";
    if (id === 3 && this.type === 2) return "gd-circle fdbanner-right";
    if (id === 3 && this.type === 3) return "cb-circle fdbanner-right";
    if (id === 3 && this.type === 4) return "od-circle fdbanner-right";

    if (id === 4 && this.type === 1) return "fd-sub-back sc-delivery all_sec";
    if (id === 4 && this.type === 2) return "gd-sub-back sc-delivery all_sec";
    if (id === 4 && this.type === 3) return "cb-sub-back sc-delivery all_sec";
    if (id === 4 && this.type === 4) return "od-sub-back sc-delivery all_sec";

    if (id === 5 && this.type === 1) return "fd-color-scheme";
    if (id === 5 && this.type === 2) return "gd-color-scheme";
    if (id === 5 && this.type === 3) return "cb-color-scheme";
    if (id === 5 && this.type === 4) return "od-color-scheme";

    if (id === 6 && this.type === 1) return "fd-link-col nav nav-tabs";
    if (id === 6 && this.type === 2) return "gd-link-col nav nav-tabs";
    if (id === 6 && this.type === 3) return "cb-link-col nav nav-tabs";
    if (id === 6 && this.type === 4) return "od-link-col nav nav-tabs";

    if (id === 7 && this.type === 1) return "fd-pc-new process-content";
    if (id === 7 && this.type === 2) return "gd-pc-new process-content";
    if (id === 7 && this.type === 3) return "cb-pc-new process-content";
    if (id === 7 && this.type === 4) return "od-pc-new process-content";

    if (id === 8 && this.type === 1) return "fd-wh-btn wh-btn";
    if (id === 8 && this.type === 2) return "gd-wh-btn wh-btn";
    if (id === 8 && this.type === 3) return "cb-wh-btn wh-btn";
    if (id === 8 && this.type === 4) return "od-wh-btn wh-btn";

    if (id === 9 && this.type === 1) return "fd-whole-package whole-package all_sec";
    if (id === 9 && this.type === 2) return "gd-whole-package whole-package all_sec";
    if (id === 9 && this.type === 3) return "cb-whole-package whole-package all_sec";
    if (id === 9 && this.type === 4) return "od-whole-package whole-package all_sec";

    if (id === 10 && this.type === 1) return "fd-bullets cus-left";
    if (id === 10 && this.type === 2) return "gd-bullets cus-left";
    if (id === 10 && this.type === 3) return "cb-bullets cus-left";
    if (id === 10 && this.type === 4) return "od-bullets cus-left";

    if (id === 11 && this.type === 1) return "fd-cus-bx-shadow cus-app-main";
    if (id === 11 && this.type === 2) return "gd-cus-bx-shadow cus-app-main";
    if (id === 11 && this.type === 3) return "cb-cus-bx-shadow cus-app-main";
    if (id === 11 && this.type === 4) return "od-cus-bx-shadow cus-app-main";

    if (id === 12 && this.type === 1) return "fd-sol-feat solution-features all_sec";
    if (id === 12 && this.type === 2) return "gd-sol-feat solution-features all_sec";
    if (id === 12 && this.type === 3) return "cb-sol-feat solution-features all_sec";
    if (id === 12 && this.type === 4) return "od-sol-feat solution-features all_sec";

    if (id === 13 && this.type === 1) return "fd-sfr-left sfr-left";
    if (id === 13 && this.type === 2) return "gd-sfr-left sfr-left";
    if (id === 13 && this.type === 3) return "cb-sfr-left sfr-left";
    if (id === 13 && this.type === 4) return "od-sfr-left sfr-left";

    if (id === 14 && this.type === 1) return "fd-packfooimg packfooimg";
    if (id === 14 && this.type === 2) return "gd-packfooimg packfooimg";
    if (id === 14 && this.type === 3) return "cb-packfooimg packfooimg";
    if (id === 14 && this.type === 4) return "od-packfooimg packfooimg";
  }

}
