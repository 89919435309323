import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-qa',
  templateUrl: './qa.component.html',
  styleUrls: ['./qa.component.scss']
})
export class QaComponent implements OnInit {

  constructor(private title: Title) {
    this.title.setTitle('Raisincode | Quality Assurance')
  }

  ngOnInit(): void {
  }

}
