import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { DevelopmentComponent } from './service/development/development.component';
import { UiuxComponent } from './service/uiux/uiux.component'
import { AppdevelopmentComponent } from './service/appdevelopment/appdevelopment.component'
import { QaComponent } from './service/qa/qa.component'
import { FaqComponent } from './faq/faq.component'
import { BlogComponent } from './blog/blog/blog.component'
import { SolutionTemplateOneComponent } from './solution/sol-template-1/sol-template-1.component';
import { PrivacypolicyComponent } from './contentpages/privacypolicy/privacypolicy.component'
import { BlogdetailComponent } from './blog/blogdetail/blogdetail.component'
import { ContactComponent } from './contact/contact.component'
import { FreequoteComponent } from './modal/freequote/freequote.component';

const routes: Routes = [
    {
    path: '',
    pathMatch: 'full',
    component: HomeComponent
  },
  {
    path: 'home',
    component: HomeComponent
  },

  
  {
    path: 'services/website-development',
    component: DevelopmentComponent
  },
    {
    path: 'services/ui-ux-design',
    component: UiuxComponent
  },
   {
    path: 'services/mobile-application-development',
    component: AppdevelopmentComponent
  },
  {
    path: 'services/quality-assurance',
    component: QaComponent
  },


  {
    path: 'solutions/:id',
    component: SolutionTemplateOneComponent
  },
  // {
  //   path: 'solutions/:id/:type',
  //   component: SolutionTemplateOneComponent
  // },


  // {
  //   path: 'resources',
  //   component: BlogComponent
  // },
  // {
  //   path: 'resources/blog/:id',
  //   component: BlogdetailComponent
  // },


  {
    path: 'privacy-policy',
    component: PrivacypolicyComponent
  },
  // {
  //   path: 'contact-us',
  //   component: ContactComponent
  // },
  // {
  //   path: 'faq',
  //   component: FaqComponent
  // }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: 'enabled', useHash: true, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
