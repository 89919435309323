<div class="web-page">
<app-header></app-header>
    <div class="blogd-page">
        <div class="blogd-main">
        <div class="conta_iner">
            <div class="bd-main">
                <div class="bd-left">
                    <div class="bb-box">
                    <figure>
                        <img src="assets/images/blog/blog1.jpg">
                    </figure>
                    <div class="bb-ontent">
                        <h3>WWDC 2021 | Apple Unveils Latest Updates for iOS 15, iPadOS 15 and more</h3>
                        <p>WWDC 2021
                            WWDC 2021 | Apple Unveils Latest Updates for iOS 15, iPadOS 15 and more
                            Leave a Comment / For Entrepreneurs / By Shivam Gupta
                            It’s that time of the year again! Apple kicked off its annual Apple Worldwide Developers Conference this year with a slew of updates across product lines. If you did not get the time to watch the live stream of the WWDC 2021 or were in a different time zone, we’ve got you covered! Here are the Apple WWDC 2021 highlights!

                            The new iOS 15 is here!
                            iOS 15 is the latest and greatest version of iOS that promises the world, and then some! Craig Federighi announced the release of this new iOS that will focus on staying connected better, finding focus in tasks and notifications, using intelligence everywhere and exploring the world. iOS WWDC 2021 updates will be available as a public beta in July, with the full release following the beta “this fall”.

                            Updates to FaceTime
                            I love FaceTime, and I use it nearly every day to speak to friends and family. Here’s what iOS 15 brings to FaceTime:

                            Voice isolation
                            FaceTiming while vacuuming or from a busy intersection? You can now (toggle to) hear people better, no matter where they are. This means that I can now vacuum the living room during conference calls! Huzzah! 

                            Spatial audio
                            I love this bit! If you’re using compatible audio hardware, you can now hear a person’s voice from where they appear on the screen. It’s magic! (I am an amateur musician, and I think spatial audio in FaceTime was the real highlight of the WWDC keynote).
                            </p>
                            <p>WWDC 2021
                            WWDC 2021 | Apple Unveils Latest Updates for iOS 15, iPadOS 15 and more
                            Leave a Comment / For Entrepreneurs / By Shivam Gupta
                            It’s that time of the year again! Apple kicked off its annual Apple Worldwide Developers Conference this year with a slew of updates across product lines. If you did not get the time to watch the live stream of the WWDC 2021 or were in a different time zone, we’ve got you covered! Here are the Apple WWDC 2021 highlights!

                            The new iOS 15 is here!
                            iOS 15 is the latest and greatest version of iOS that promises the world, and then some! Craig Federighi announced the release of this new iOS that will focus on staying connected better, finding focus in tasks and notifications, using intelligence everywhere and exploring the world. iOS WWDC 2021 updates will be available as a public beta in July, with the full release following the beta “this fall”.

                            Updates to FaceTime
                            I love FaceTime, and I use it nearly every day to speak to friends and family. Here’s what iOS 15 brings to FaceTime:

                            Voice isolation
                            FaceTiming while vacuuming or from a busy intersection? You can now (toggle to) hear people better, no matter where they are. This means that I can now vacuum the living room during conference calls! Huzzah! 

                            Spatial audio
                            I love this bit! If you’re using compatible audio hardware, you can now hear a person’s voice from where they appear on the screen. It’s magic! (I am an amateur musician, and I think spatial audio in FaceTime was the real highlight of the WWDC keynote).
                            </p>
                            <p>WWDC 2021
                            WWDC 2021 | Apple Unveils Latest Updates for iOS 15, iPadOS 15 and more
                            Leave a Comment / For Entrepreneurs / By Shivam Gupta
                            It’s that time of the year again! Apple kicked off its annual Apple Worldwide Developers Conference this year with a slew of updates across product lines. If you did not get the time to watch the live stream of the WWDC 2021 or were in a different time zone, we’ve got you covered! Here are the Apple WWDC 2021 highlights!

                            The new iOS 15 is here!
                            iOS 15 is the latest and greatest version of iOS that promises the world, and then some! Craig Federighi announced the release of this new iOS that will focus on staying connected better, finding focus in tasks and notifications, using intelligence everywhere and exploring the world. iOS WWDC 2021 updates will be available as a public beta in July, with the full release following the beta “this fall”.

                            Updates to FaceTime
                            I love FaceTime, and I use it nearly every day to speak to friends and family. Here’s what iOS 15 brings to FaceTime:

                            Voice isolation
                            FaceTiming while vacuuming or from a busy intersection? You can now (toggle to) hear people better, no matter where they are. This means that I can now vacuum the living room during conference calls! Huzzah! 

                            Spatial audio
                            I love this bit! If you’re using compatible audio hardware, you can now hear a person’s voice from where they appear on the screen. It’s magic! (I am an amateur musician, and I think spatial audio in FaceTime was the real highlight of the WWDC keynote).
                            </p>
                            <div class="prev-next-main">
                                <button>< Previous post</button>
                                <button>Next post ></button>
                            </div>
                    </div>
                    </div>
                </div>
                <div class="bd-right">
                <h5>Let’s hear out your idea !</h5>
                <form>
                        <ul>
                            <li>
                                <mat-form-field [floatLabel]="'never'">
                                    <input matInput type="text" placeholder="Name" name="name">
                                </mat-form-field>
                            </li>
                            <li>
                                <mat-form-field [floatLabel]="'never'">
                                    <input matInput type="text" placeholder="Email" name="name">
                                </mat-form-field>
                            </li>
                            <li>
                                <mat-form-field [floatLabel]="'never'">
                                    <input matInput type="text" placeholder="Budget" name="name">
                                </mat-form-field>
                            </li>
                            <li>
                                <mat-form-field class="example-full-width" [floatLabel]="'never'">
                                    <textarea matInput placeholder="Requirement"></textarea>
                                </mat-form-field>
                            </li>
                        </ul>
                        <button class="btn" data-toggle="modal" data-target="#getquote">
                            <figure><img src="assets/images/blog/flyer.svg"></figure>
                            Get a Free Quote
                        </button>
                    </form>
                </div>
            </div>
        </div>
        </div>
        <!-- recent post start -->
        <section class="recent-post">
            <div class="conta_iner">
                <div class ="rp-main">
                <h5>Recent Posts</h5>
                    <ul>
                        <li>
                            <div class="rp-box">
                                <figure>
                                    <img src="assets/images/blog/blog1.jpg">
                                </figure>  
                                <h5>Spotify Takes on Clubhouse and Twitter Spaces with Spotify Greenroom</h5>
                                <p>The social audio app scene, with new entrants announced every few months, is heating up. Clubhouse is the original social …</p>  
                                <button>Read more ></button>
                            </div>
                        </li>
                        <li>
                            <div class="rp-box">
                                <figure>
                                    <img src="assets/images/blog/blog2.jpg">
                                </figure>  
                                <h5>How To Upload An App To Google Play Store?</h5>
                                <p>No one can doubt the popularity and dominance of the Google Play Store in the domain of mobile applications and software.</p>  
                                <button>Read more ></button>
                            </div>
                        </li>
                        <li>
                            <div class="rp-box">
                                <figure>
                                    <img src="assets/images/blog/blog3.jpg">
                                </figure>  
                                <h5>Impact of eScooters on the urbanized travel economy</h5>
                                <p>Learn about the transport situation and how its dominated by on-demand and ride sharing products like eScooters.</p>  
                                <button>Read more ></button>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        <!-- recent post end -->
    </div> 
    <app-footer></app-footer>
</div>
 


