import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-freequote',
  templateUrl: './freequote.component.html',
  styleUrls: ['./freequote.component.scss']
})
export class FreequoteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
