
<!-- Modal -->
<div id="getquote" class="modal fade" role="dialog">
  <div class="modal-dialog">

    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-body">
     <div class="mdl-img"> <figure>
        <img src="assets/images/modal.svg">
      </figure>
      </div>
       <h4>Get a free quote</h4>
       <p>Lorem ipsum lorem ipsum lorem ipsum</p>
       
                <form>
                    <ul>
                        <li>
                            <mat-form-field [floatLabel]="'never'">
                                <input matInput type="text" placeholder="Name" name="name">
                            </mat-form-field>
                        </li>
                        <li>
                            <mat-form-field [floatLabel]="'never'">
                                <input matInput type="text" placeholder="Email" name="name">
                            </mat-form-field>
                        </li>
                        <li>
                            <mat-form-field [floatLabel]="'never'">
                                <input matInput type="text" placeholder="Budget" name="name">
                            </mat-form-field>
                        </li>
                        <li>
                            <mat-form-field class="example-full-width" [floatLabel]="'never'">
                                <textarea matInput placeholder="Requirement"></textarea>
                            </mat-form-field>
                        </li>
                    </ul>
                  <div class="sub-btn">  <button class="btn">
                       Submit
                    </button>
                    </div>
                </form>
      </div>
      
    </div>

  </div>
</div>