<div class="web-page">
<app-header></app-header>
    <div class="contact-us">
        <div class="conta_iner">
            <div class="contact-main">
                <div class="contact-left">
                    <h1>Contact Us</h1>
                    <p>Have any question in mind ? Let us know and we will get back to you as soon as we can !</p>
                     <form>
                    <ul>
                        <li>
                            <mat-form-field [floatLabel]="'never'">
                                <input matInput type="text" placeholder="Name" name="name">
                            </mat-form-field>
                        </li>
                        <li>
                            <mat-form-field [floatLabel]="'never'">
                                <input matInput type="text" placeholder="Email" name="name">
                            </mat-form-field>
                        </li>
                        <li>
                            <mat-form-field class="example-full-width" [floatLabel]="'never'">
                                <textarea matInput placeholder="Message"></textarea>
                            </mat-form-field>
                        </li>
                    </ul>
                    <button class="">Send</button>
                </form>
                </div>
                <div class="contact-right">
                    <h5>Info</h5>
                   
                    <ul>
                        <li>
                            <div class="info-main">
                                <div class="info-left">
                                 <figure>
                                    <img src="assets/images/contact/mail.svg">
                                </figure>
                                </div>
                                <div class="info-right">
                                    <p><a href="mailto:support@raisincode.com">support@raisincode.com</a>  <small> (For any queries)</small></p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="info-main">
                                <div class="info-left">
                                 <figure>
                                    <img src="assets/images/contact/mail.svg">
                                </figure>
                                </div>
                                <div class="info-right">
                                <p><a href="mailto:contact@raisincode.com">contact@raisincode.com</a>  <small> (To discuss an idea)</small></p>
                                </div>
                            </div>
                        </li>
                         <li>
                            <div class="info-main">
                                <div class="info-left">
                                 <figure>
                                    <img src="assets/images/contact/call.svg">
                                </figure>
                                </div>
                                <div class="info-right">
                                <p><a href="{{'tel:'+'+91-98727-68848'}}">+91-98727-68848</a></p>
                                </div>
                            </div>
                        </li>
                         <li>
                            <div class="info-main">
                                <div class="info-left">
                                 <figure>
                                    <img src="assets/images/contact/address.svg">
                                </figure>
                                </div>
                                <div class="info-right">
                                <p>Mohali, India</p>
                                </div>
                            </div>
                        </li>
                         <li>
                            <div class="info-main">
                                <div class="info-left">
                                 <figure>
                                    <img src="assets/images/contact/clock.svg">
                                </figure>
                                </div>
                                <div class="info-right">
                                <p>Always Open</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="wave-btm">
                        <figure>
                            <img src="assets/images/contact/wave.svg">
                        </figure>
                    </div>        
                </div>
            </div>
        </div>
    </div>
<app-footer></app-footer>
</div>
